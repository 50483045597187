import {
  identity,
  mergeDeepRight,
  pick,
  prop,
  reduce,
} from 'ramda'

import { minutes } from 'milliseconds'
import { normalize } from 'normalizr'

import createLogger from '~/src/Lib/Logging'
import * as schemas from '~/src/Store/Schemas'

import { queryClient } from './Api'

const logger = createLogger('IO/Messages')

const mergeEntities = reduce(mergeDeepRight, {})

const toUpdate = []

export const PACKET_HANDLERS = {
  DEFAULT: packet => logger.warn('unknown packet configuration', { packet, schemas }),
  PERFORM_ACTION: (packet, store) => {
    const { payload } = packet
    if (!Array.isArray(payload)) {
      logger.debug('PERFORM_ACTION invalid payload:', payload)
      return
    }
    payload.flatMap(prop('function')).forEach(f => {
      // simple action: "doMarkHarvestListAsOutdated"
      if (typeof f === 'string' && f in store) {
        store[f]()
        return true
      }
      // complex action: { actionCreator: "doRoomFetch", args: [1] }
      if (f?.actionCreator && f.actionCreator in store) {
        store.dispatch(f)
        return true
      }

      logger.debug('PERFORM_ACTION with invalid actionCreator', {
        actionCreator: f,
      })
      return false
    })
  },
  ENTITY_CHANGE: (packet, store) => {
    const { payload } = packet

    if (Array.isArray(payload) && payload.length) {
      const merged = mergeEntities(payload)

      store.doEntitiesReceived(merged)
      toUpdate.push(...Object.entries(merged))
      requestIdleCallback(deadline => {
        const processUpdates = () => {
          const [key, data] = toUpdate.shift()
          queryClient.cancelQueries({ queryKey: [key] })
          queryClient.setQueriesData({ queryKey: [key] }, oldData => {
            if (Array.isArray(oldData) && oldData.some(e => e.id in data)) {
              return oldData.map(e => (e.id in data ? data[e.id] : e))
            }
            if (Array.isArray(oldData?.results) && oldData.results.some(e => e.id in data)) {
              return {
                ...oldData,
                results: oldData.results.map(e => (e.id in data ? data[e.id] : e))
              }
            }
            if (oldData?.id && oldData.id in data) {
              return data[oldData.id]
            }
            return oldData
          })
        }
        if (deadline.didTimeout) {
          processUpdates()
          return
        }
        while (deadline.timeRemaining() > 0 && toUpdate.length) {
          processUpdates()
        }
      })
      return true
    }

    logger.debug('ENTITY_CHANGE with invalid data from EventStream:', packet)
    return false
  }
}

const invalidSchemas = new Map()

const PACKET_INGESTERS = {
  DEFAULT: identity,
  ENTITY_CHANGE: packet => {
    const { entity, payload, many } = packet
    const { [entity]: entitySchema } = schemas
    if (!entitySchema) {
      const lastInvalid = invalidSchemas.get(entity) ?? 0
      const now = Date.now()
      if (now - lastInvalid > minutes(60)) {
        invalidSchemas.set(entity, now)
        logger.debug('missing schema for entity', entity)
      }
      return null
    }
    return normalize(payload, many ? [entitySchema] : entitySchema).entities
  },
  PERFORM_ACTION: pick(['function'])
}
const PACKET_ID = {
  PERFORM_ACTION: prop('action'),
  ENTITY_CHANGE: prop('entity'),
}

export class Queue extends Map {
  push(item) {
    try {
      const { [item.action]: getId = PACKET_ID.DEFAULT } = PACKET_ID
      const { [item.action]: ingester = PACKET_INGESTERS.DEFAULT } = PACKET_INGESTERS
      const id = getId(item)
      if (!id) {
        logger.debug('missing id for packet', item)
        return
      }
      const ingested = ingester(item)
      if (!ingested) return
      const { action = item.action, payload = [] } = this.get(id) || {}
      this.set(id, { action, payload: payload.concat(ingested) })
    } catch (err) {
      logger.error('SSE packet push error', err)
    }
  }

  shift() {
    if (this.size > 0) {
      const [key, item] = this.entries().next().value
      this.delete(key)
      return item
    }
    return undefined
  }
}
