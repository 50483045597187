import { path, pick, prop } from 'ramda'
import { createSelector } from 'redux-bundler'

import { defer } from '~/src/Lib/Utils'

import { createAuthenticatedSelector } from '../../utils'
import { getLSM, setLSM } from './utils'

export const CURRENT_FACILITY_ID = 'CURRENT_FACILITY_ID'
export const NEXT_FACILITY_ID = 'NEXT_FACILITY_ID'
export const NEXT_FACILITY_URL = 'NEXT_FACILITY_URL'

const WHITELISTED_ENTITY_KEYS = [
  'eventTypes',
  'facilities',
  'itemCategories',
  'models',
  'noteCategories',
  'notificationTypes',
  'organizations',
  'packageStatuses',
  'permissions',
  'units',
]

const WHITELISTED_QUERY_KEYS = new Set([
  'unit_conversions',
])

const WHITELISTED_STORE_KEYS = [
  'alerts',
  'appTime',
  'accountCreation',
  'auth',
  'debug',
  'dimensions',
  'io',
  'locale',
  'maintenanceMode',
  'online',
  'snackbar',
  'sockets',
  'support',
  'system',
  'url',
  'mySettings',
  'userSettings',
]

const storePicker = pick(WHITELISTED_STORE_KEYS)
const entityPicker = pick(WHITELISTED_ENTITY_KEYS)

const defaultState = {
  current: getLSM().facility ?? null,
  next: null,
  nextURL: null,
  ts: 0,
}

export default {
  name: 'currentFacility',
  reducer: (state = defaultState, action = {}) => {
    let nextState = state
    switch (action.type) {
      case CURRENT_FACILITY_ID:
        nextState = {
          ...state,
          current: action.payload,
          next: defaultState.next,
        }
        break
      case NEXT_FACILITY_ID:
        nextState = {
          ...state,
          next: action.payload,
        }
        break
      case NEXT_FACILITY_URL:
        nextState = {
          ...state,
          nextURL: action.payload,
        }
        break
      default:
      // pass
    }
    if (nextState !== state) {
      nextState.ts = Date.now()
    }
    return nextState
  },
  doCurrentFacilitySet: payload => ({ type: CURRENT_FACILITY_ID, payload }),
  doFacilitySwitch: payload => ({ dispatch, queryClient, store }) => {
    if (!payload || !payload.facility) {
      console.warn('[doFacilitySwitch] invalid payload:', payload)
      return
    }
    setLSM(payload)
    dispatch({ actionCreator: 'doUpdateUrl', args: ['/switching'] })

    defer(() => {
      const prevState = store.getState() ?? {}
      const { entities: prevEntities = {} } = prevState

      dispatch({
        type: 'REPLACE_STATE',
        payload: {
          ...storePicker(prevState),
          currentFacility: {
            next: payload.facility,
            nextURL: payload.url ?? defaultState.nextURL,
            ts: Date.now(),
          },
          entities: entityPicker(prevEntities),
          me: { currentMembership: payload.id },
        }
      })

      if (queryClient && 'clear' in queryClient) {
        queryClient.clear()
      }
    }, defer.priorities.highest)
  },
  doNextFacilitySet: payload => ({ type: NEXT_FACILITY_ID, payload }),
  doPostSwitchRedirect: nextURL => ({ dispatch }) => {
    dispatch({ actionCreator: 'doUpdateUrl', args: [nextURL] })
    dispatch({ type: NEXT_FACILITY_URL, payload: null })
  },
  selectCurrentFacilityId: path(['currentFacility', 'current']),
  selectCurrentFacilityRoot: prop('currentFacility'),
  selectNextFacilityId: path(['currentFacility', 'next']),
  selectSwitchingFacilities: createSelector(
    'selectCurrentFacilityRoot',
    ({ current, next }) => Boolean(next && current != next)
  ),
  reactCurrentFacilityId: createAuthenticatedSelector({
    dependencies: ['selectCurrentFacilityId', 'selectNextFacilityId'],
    resultFn: (current, next) => {
      if (current) return null
      const { facility } = getLSM()
      if (!facility || next === facility) return null
      return { type: NEXT_FACILITY_ID, payload: facility }
    }
  }),
  reactPostSwitchRedirect: createAuthenticatedSelector({
    dependencies: ['selectCurrentFacilityRoot', 'selectMeLastSuccess'],
    resultFn: (root, lastMeFetch) => {
      const { nextURL } = root
      if (!nextURL || nextURL.startsWith(`/f/${root.next}`)) return null

      if (lastMeFetch < root.ts) return null
      return {
        actionCreator: 'doPostSwitchRedirect',
        args: [root.nextURL]
      }
    }
  }),
}
