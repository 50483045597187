import { SvgIcon } from '@mui/material'

import useConcurrentConnect from '~/src/Lib/useConcurrentConnect'

export const path = `
M16.78 3.898a.993.993 0 0 0 .178-1.384.974.974 0 0 0-1.373-.18c-.508.394-.874.862-1.07
1.408-.195.543-.192 1.082-.099 1.573.159.832.62 1.678.993 2.365l.002.002.107.197c.438
.812.736 1.433.787 1.975.023.242-.007.448-.096.643-.09.199-.268.445-.624.721a.993.993
0 0 0-.178 1.385.974.974 0 0 0 1.373.18c.554-.43.961-.916 1.21-1.466a3.211 3.211 0 0 0
.265-1.65c-.096-1.01-.611-1.98-1.018-2.732l-.065-.121c-.425-.785-.736-1.36-.833-1.871
-.045-.235-.03-.4.017-.53.046-.127.151-.303.424-.515ZM20.696 3.898a.993.993 0 0 0 .178
-1.384.974.974 0 0 0-1.373-.18c-.508.394-.874.862-1.07 1.408-.195.543-.192 1.082-.099
1.573.159.832.62 1.678.994 2.365l.108.2c.439.811.736 1.432.787 1.974.023.242-.007.448
-.095.643-.09.199-.268.445-.625.721a.993.993 0 0 0-.178 1.385.974.974 0 0 0 1.373.18
c.554-.43.962-.916 1.21-1.466a3.21 3.21 0 0 0 .265-1.65c-.095-1.01-.61-1.98-1.017-2.732
l-.066-.121c-.424-.785-.735-1.36-.833-1.871-.044-.235-.03-.4.017-.53.046-.127.152-.303
.424-.515ZM2.87 10.66l.147-.634.564.184c.084.028 1.518.508 2.867 1.558-.234.35-.42.72
-.573 1.102a9.436 9.436 0 0 0-2.02-1.204c-.158 1.171-.304 3.586.815 5.251.617.918 1.292
1.173 1.778 1.224-.392-.97-1.08-2.302-1.89-3.51l.91-.761c.367.545.697 1.105 1.004 1.657
.053-1.4.469-2.887 1.73-3.942 1.99-1.661 4.53-1.277 4.637-1.26l.462.074.025.52c.114
2.302.242 4.912-2.28 7.019-.695.58-1.475 1.037-2.096 1.354-.182-1.579.03-3.596 1.3-4.657
l-.682-1.017c-.144.12-.277.252-.402.39l-.016.018a.774.774 0 0 1-.01.013c-.885 1.001
-1.277 2.422-1.37 3.786a9.609 9.609 0 0 0 .092 2.136c.097.6.279 1.065.336 1.15l-.912.761
c-.284-.423-.43-.974-.545-1.684a10.133 10.133 0 0 1-.09-.763c-.756.005-1.907-.278-2.892
-1.746-1.85-2.754-.93-6.845-.89-7.018Z
`

const Icon = props => {
  const { dataTypes } = useConcurrentConnect('selectDataTypes')
  const { vpd: dataType = {} } = dataTypes
  const { color = 'olivedrab' } = dataType
  const fill = props.color || props.htmlColor ? 'currentColor' : color

  return (
    <SvgIcon {...props}>
      <path d={path} fill={fill} fillRule="evenodd" clipRule="evenodd" />
    </SvgIcon>
  )
}

export default Icon
