// shim requestIdleCallback for Safari
import '~/src/Lib/polyfills'

import { assocPath, omit } from 'ramda'

import { track } from '@minimal-analytics/ga4'
import * as sentry from '@sentry/react'

import config from '~/src/App/config'
import { refreshTokens } from '~/src/Lib/Auth'
import getGlobal from '~/src/Lib/getGlobal'
import safeStorage from '~/src/Lib/safeStorage'
import { ACCOUNT_CREATION } from '~/src/Routes/Register/bundle'
import getStore from '~/src/Store'
import { defaultState as authDefaultState } from '~/src/Store/bundles/auth'
import { getLSM } from '~/src/Store/bundles/me'
import { load } from '~/vendor/redux-localstorage-simple'

import { HARVESTING_FLOW } from './Flow/constants'
import { FACILITY_SEGMENT_PATTERN } from './Routes/constants'
import { isPublicRoute } from './Routes/utils'

const localStorage = safeStorage('local')

export function initThirdParty(logger) {
  const global = getGlobal()
  if (config.GA4_TRACKING) {
    logger.debug('starting Google Analytics v4 tracking')
    track(config.GA4_TRACKING)
  }

  if (config.SENTRY_DSN) {
    logger.debug('starting Sentry instrumentation')
    sentry.init({
      dsn: config.SENTRY_DSN,
      release: `v${config.VERSION}`,
      environment: config.ENVIRONMENT,
      integrations: [
        sentry.browserTracingIntegration({
          shouldCreateSpanForRequest: url => Boolean(url.match(/^https?:\/\/(localhost|[0-9a-z-.]+\.phytochrome\.io|(app|api)\.aroya\.io)/))
        }),
        sentry.replayIntegration({
          blockAllMedia: false,
          maskAllInputs: false,
          maskAllText: false,
          networkDetailAllowUrls: ['https://api.aroya.io', global.location.origin],
          networkRequestHeaders: ['X-App', 'X-Client', 'X-Client-Version', 'X-Facility', 'If-Unmodified-Since'],
          networkResponseHeaders: ['Allow'],
        })
      ],
      replaysSessionSampleRate: 1,
      tracesSampleRate: 0.1,
      tracePropagationTargets: [getGlobal().location.origin, config.API_URL]
    })
    sentry.setTag('app', config.APP)
  }
}

export async function prepareMockServiceWorker(apiMocked, logger) {
  if (apiMocked) {
    const { default: handlers } = await import('~/test/mocks/handlers')
    const { setupWorker, rest } = await import('msw')
    const global = getGlobal()
    const worker = setupWorker(...handlers)
    global.msw = {
      worker,
      rest
    }
    await worker.start()
    let resolved = false
    let resolve
    let timeout
    let interval

    worker.events.on('request:start', req => {
      if (!resolved) {
        logger.debug('marking msw ready')
        resolve(worker)
        clearTimeout(timeout)
      }
      const headers = req.headers.all()
      const client = headers['x-client']
      if (!client) return
      const appURL = global.location.href
      global.msw.appURL = appURL
      global.msw.clientID = client
    })

    await new Promise(r => {
      resolve = r
      timeout = setTimeout(() => {
        logger.debug('timed out waiting for msw')
        resolved = true
        r()
        clearInterval(interval)
      }, 3000)
      interval = setInterval(async () => {
        try {
          // deepcode ignore PromiseNotCaughtGeneral: We are catching exceptions
          await fetch(new URL('/msw', location.href)).then(response => {
            if (response.status === 204) {
              clearInterval(interval)
              clearTimeout(timeout)
              resolved = true
              resolve()
            }
          })
        } catch (err) {
          logger.debug('error while checking if msw is active:', err)
        }
      }, 250)
    })
    if (apiMocked) {
      import('~/test/e2e/utils/idb').then(pkg => {
        logger.debug('idb:', pkg)
        getGlobal().idb = pkg
      }).catch(error => logger.error('error importing idb:', error))
    }
    return worker
  }
  return null
}

const setupUnsavedHarvestingWarning = (store, logger) => {
  const window = getGlobal()
  const setupUnloadHandler = () => {
    logger.debug('[setupUnsavedHarvestingWarning] adding unload handler')
    const unloadHandler = event => {
      logger.debug('[unloadHandler] running')
      const haveUnsaved = store.selectHaveHarvestingUnsavedStates()
      if (haveUnsaved) {
        event.preventDefault()
        logger.debug('[unloadHandler] We have unsaved changes')
        store.doShowUnsavedHarvestingLeaveDialog()
        return 'HAS_UNSAVED'
      }
      return null
    }
    window.addEventListener('beforeunload', unloadHandler)
  }

  window.addEventListener('click', setupUnloadHandler, { capture: true, once: true })
}

const e2eCacheCleaner = omit([
  'deviceList',
  'harvestList',
  'irrigationControllerList',
  'journal',
  'scales',
  'url'
])

export async function initializeAppState(indexedDBData, logger) {
  const { id: currentMembership, facility: currentFacility } = getLSM()
  let user
  let facilities
  let refreshFailed = false
  try {
    ({ user, facilities } = await refreshTokens())
  } catch (error) {
    refreshFailed = true
    logger.error('error refreshing tokens:', error)
  }

  let initialData
  // load E2E redux cache if available
  const e2eCache = localStorage.getItem('__AROYA_STORE__')
  const auth = localStorage.getItem(config.AUTH_TOKEN_KEY)
  if (auth && e2eCache) {
    try {
      initialData = e2eCacheCleaner(JSON.parse(e2eCache))
      logger.debug('initialized data from localStorage:', initialData)
    } catch (error) {
      logger.error('error parsing localStorage:', error)
    }
  }
  // if no E2E redux cache, load from redux-bundler cache & redux-localstorage-simple
  if (!initialData) {
    const { userSettings, mySettings, ...restLoad } = load({
      states: [HARVESTING_FLOW, ACCOUNT_CREATION, 'mySettings'],
      namespace: `__${config.APP}__`
    })
    initialData = {
      ...(indexedDBData ?? {}),
      ...restLoad,
      // TODO: get rid of userSettings in May 2025 or when v4.0.0 is released
      mySettings: { ...userSettings, ...mySettings },
      auth: {
        ...authDefaultState,
        authenticated: !!user,
      },
    }
    logger.debug('auth:', initialData.auth)
    const { location } = getGlobal()
    const url = location.href.replace(location.origin, '')

    const match = url.match(FACILITY_SEGMENT_PATTERN)
    if (match && match[1] && match[1] != currentFacility) {
      logger.debug('url is for another facility:', { nextFacility: match[1], currentFacility })
      initialData.currentFacility = {
        current: currentFacility ?? null,
        next: Number(match[1]),
        nextURL: url,
        ts: Date.now(),
      }
    } else if (currentMembership && currentFacility) {
      assocPath(['currentFacility', 'current'], currentFacility, initialData)
    } else if (facilities?.length) {
      let facility = facilities.find(f => f.id == currentFacility)
      if (!facility) {
        ({ 0: facility } = facilities.slice().sort((a, b) => a.id - b.id))
      }
      assocPath(['currentFacility', 'nextFacility'], facility.id, initialData)
    }
  }

  const reduxStore = await getStore(initialData)

  if (refreshFailed && !isPublicRoute(location.pathname)) {
    logger.warn('Token refresh failed. Redirecting to login page.', { currentFacility, currentMembership })
    location.href = new URL('/login', location.href)
  }

  setupUnsavedHarvestingWarning(reduxStore, logger)

  return reduxStore
}
