import { curry, pick } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import { track } from '@minimal-analytics/ga4'
import * as sentry from '@sentry/react'
import ms from 'milliseconds'

import config from '~/src/App/config'
import countries from '~/src/data/states.json'
import getGlobal from '~/src/Lib/getGlobal'
import { defer, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { REACTOR_PRIORITIES, URL_ACTION } from '~/src/Store/constants'

import { createAuthenticatedSelector } from '../utils'

const IGNORE_URL_PATTERN = /aptrinsic|google|pendo|sentry/

const global = getGlobal()
const statePairs = countries.flatMap(({ states }) => (
  states.map(({ abbreviation, name }) => [abbreviation, name])
))

export const stateMatcher = new RegExp(`${
  statePairs.map(([abbreviation, name]) => `\\b${abbreviation}\\b|\\b${name}\\b`).join('|')
}`)

export const normalizedStates = statePairs.reduce((normalized, [abbreviation, name]) => ({
  ...normalized,
  [abbreviation]: abbreviation,
  [name]: abbreviation,
}), {})

const pendoFacilityPicker = pick([
  'id',
  'name',
  'app',
  'metrc',
  'outdoor',
  'state',
  'status',
  'supportCategory',
  'tier'
])

const initializeServices = async ({ store }, noReturn) => {
  const storeProps = store.select([
    'selectCurrentFacility',
    'selectCurrentMembership',
    'selectFacilityRoles',
    'selectLocale',
    'selectMe'
  ]) ?? EMPTY_OBJECT
  const {
    currentFacility: facility,
    currentMembership: member,
    facilityRoles: roles,
    locale,
    me,
  } = storeProps
  if (!member || !facility || !me) return null
  const userId = `${member?.facility}_${member?.user}`
  const { [member?.role]: role } = roles

  let pendo = {}
  if (member && ('pendo' in global || 'aptrinsic' in global)) {
    const account = pendoFacilityPicker(facility)
    if (!account.state && facility.location) {
      const stateMatch = facility.location.match(stateMatcher)
      if (stateMatch) {
        const [matchedState] = stateMatch
        const state = normalizedStates[matchedState]
        account.state = state
      }
      if (!account.state && facility.location) {
        account.location = facility.location
      }
    }
    pendo = {
      visitor: {
        id: userId,
        name: me.fullName,
        admin: member.admin,
        email: me.email,
        role: role?.name ?? 'USER',
        language: me.languagePref ?? locale.replace('-', '_'),
        dateJoined: me.dateJoined,
      },
      account,
      apiKey: config.PENDO_KEY,
      clientVersion: config.VERSION,
    }
    if (global.pendo && global.pendo.getAccountId) {
      const pendoInitialize = global.pendo.getAccountId()
        ? global.pendo.updateOptions
        : global.pendo.initialize
      pendoInitialize(pendo)
    }
  }

  if (!member) {
    console.warn('[initializeServices] No membership set when initializing third-party services.')
    return null
  }

  if (config.GA4_TRACKING) {
    track(config.GA4_TRACKING, { type: 'page_view', event: { 'ep.user_id': userId } })
  }

  if (config.SENTRY_DSN) {
    sentry.configureScope(scope => {
      scope.setUser({ id: userId, email: me.email })
    })
  }

  return noReturn ? undefined : { pendo }
}

const initialBundle = createAsyncResourceBundle({
  actionBaseType: 'THIRD_PARTY',
  getPromise: initializeServices,
  name: 'thirdParty',
  staleAfter: ms.minutes(5),
})

export default {
  ...initialBundle,
  init: store => {
    initializeServices({ store }, true)
  },
  getMiddleware: () => curry((store, next, action) => {
    if (config.GA4_TRACKING && action?.type === URL_ACTION) {
      const { url: nextUrl } = action.payload
      const { pathname, search, hash } = new URL(nextUrl)
      const { pathname: prevPathname, search: prevSearch, hash: prevHash } = store.selectUrlObject()
      const pathChanged = prevPathname !== pathname
      const hashChanged = !pathChanged && hash !== prevHash && (hash.startsWith('#/') || prevHash.startsWith('#/'))
      const searchChanged = !pathChanged && !hashChanged && !pathname.startsWith('/harvests') && !pathname.startsWith('/rooms') && search !== prevSearch
      if (pathChanged || hashChanged || searchChanged) {
        defer(() => {
          const { facility, user } = store.selectCurrentMembership() ?? EMPTY_OBJECT
          const event = { 'ep.user_id': `${facility}_${user}` }
          if (hash.startsWith('#/')) {
            event['ep.hash_route'] = hash
          }
          track(config.GA4_TRACKING, { type: 'page_view', event })
        })
      }
    }

    return next(action)
  }),
  selectThirdPartyId: createSelector('selectCurrentMembership', currentFacility => {
    if (!currentFacility) return ''
    const { facility, user } = currentFacility
    return `${facility}_${user}`
  }),
  reactUpdateThirdParty: createAuthenticatedSelector({
    dependencies: [
      'selectThirdPartyShouldUpdate',
      'selectCurrentMembership',
    ],
    resultFn: (shouldUpdate, currentMembership) => {
      if (shouldUpdate && currentMembership?.id && currentMembership?.facility) {
        return { actionCreator: 'doFetchThirdParty', priority: REACTOR_PRIORITIES.HIGH }
      }
      return null
    }
  }),
}
