import { useEffect, useRef } from 'react'

import memoizeOne from 'memoize-one'
import {
  always,
  either,
  path,
  pick,
} from 'ramda'

import {
  activeUserAllowedTypes,
  annotationTypeMap,
  annotationTypes,
  defaultAllowedTypes,
} from '~/src/Annotations/constants'
import { selectZonesForHarvest } from '~/src/Harvest/bundle'
import {
  defaultMemoizeOneShallowEquals,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  getDateTime,
  getId,
  shallowEquals,
} from '~/src/Lib/Utils'
import { DEFAULT_TIMEFRAME, SETTINGS_PATHS } from '~/src/Room/constants'
import { useConnectModern } from '~/src/Store/hooks/useConnectModern'

import { defaultState } from './defaults'
import { createNamespacedLogger } from './logger'

const logger = createNamespacedLogger('utils#query')

export const getQueryParam = (query, paramName, defaultValue) => {
  const param = query[paramName]
  const paramType = typeof param
  if (paramType === 'undefined') return defaultValue
  const defaultType = typeof defaultValue
  // this allows use to use key=true|false in the query and get a boolean
  if (paramType !== defaultType && paramType === 'string' && defaultValue != null) {
    try {
      return JSON.parse(param)
    } catch (error) {
      logger.debug('Failed to parse query param', { paramName, param, error })
      return defaultValue
    }
  }
  return param
}

const stateToUrlMap = {
  activeAnnotationUser: 'annotationUser',
  ...annotationTypes.reduce((queryParams, annotationType) => {
    if ('queryParam' in annotationType) {
      queryParams[annotationType.queryParam] = false
    }
    return queryParams
  }, {}),
  chartTimeframe: 'timeframe',
  cursorMode: false,
  individualGraphs: false,
  individualSensors: false,
  journalOpen: false,
  mainGraphZone: 'displayedZone',
  manualFrom: 'from',
  manualTo: 'to',
  openAnnotation: 'annotation',
  selectedHarvest: 'harvest',
  selectedZones: 'zones',
  selectedDataTypes: 'dataTypes',
  showRoomAvg: false,
  showYAxis: false,
  viewMode: false,
}
const stateToUrlKeys = Object.freeze(Object.keys(stateToUrlMap))

export const urlToStateMap = Object.freeze(Object.fromEntries(
  Object.entries(stateToUrlMap)
    .map(([key, val]) => [val || key, key])
))
const urlToStateKeys = Object.freeze(Object.keys(urlToStateMap))
const pickStateFromQuery = pick(urlToStateKeys)

const nonTransferableKeys = new Set(['harvest', 'zones', 'annotation'])

const getStateForUrl = pick(stateToUrlKeys)

const defaultValueTests = {
  selectedDataTypes: (key, value, state) => {
    const { initialized: loaded, loading } = state
    if (loading || !loaded) return true
    return defaultValueTests.fallback(key, value)
  },
  selectedZones: (_, val, state) => {
    const { growlog, room, harvest } = state
    const harvestZones = selectZonesForHarvest(harvest)
    const zones = growlog && harvest ? harvestZones : (room?.zones ?? EMPTY_ARRAY).map(getId)

    return zones?.every(zoneId => val.includes(zoneId)) || !val?.length
  },
  selectedHarvest: (_, val) => !val?.id,
  fallback: (key, val) => {
    if (typeof val === 'object' && val != null) {
      return shallowEquals(defaultState[key], val)
    }
    return defaultState[key] === val
  },
  chartTimeframe: (key, val, state) => {
    const { growlog, harvest } = state

    if (growlog && harvest) {
      const now = getDateTime('now')
      return val === (
        now > getDateTime(harvest.endDate ?? now)
          ? 'selectedHarvest'
          : DEFAULT_TIMEFRAME
      )
    }

    return defaultValueTests.fallback(key, val)
  },
}

const isDefaultVal = (...args) => {
  const [key] = args
  const getIsDefaultVal = defaultValueTests[key] ?? defaultValueTests.fallback
  return getIsDefaultVal(...args)
}

const persistentSettings = [
  'individualGraphs',
  'individualSensors',
  'cursorMode',
  'dataTypes',
  'journalOpen',
  'from',
  'to',
  'showYAxis',
  'showRoomAvg',
  'timeframe',
  'viewMode',
]

const persistPicker = pick(persistentSettings)

const getQuery = memoizeOne(
  /**
   * Creates query params or persistent settings objects
   * @param {object} state
   * @param {boolean} withDefaults
   * @returns {object}
   */
  (state, withDefaults = false) => {
    const {
      activeAnnotationTypes = EMPTY_ARRAY,
      activeAnnotationUser,
      growlog,
    } = state

    const currState = getStateForUrl(state)

    const query = Object.entries(currState).reduce((acc, [key, val]) => {
      if (growlog && key === 'selectedHarvest') return acc
      const isDefault = isDefaultVal(key, val, state)

      if (val == null && !isDefault) {
        return acc
      }

      const finalKey = stateToUrlMap[key] || key

      return {
        ...acc,
        [finalKey]: isDefault && !withDefaults ? undefined : val?.id ?? val,
      }
    }, {})

    if (activeAnnotationUser) {
      query.annotationUser = activeAnnotationUser.id
    }

    const hiddenTypes = (activeAnnotationUser ? activeUserAllowedTypes : defaultAllowedTypes)
      .filter(t => !activeAnnotationTypes.includes(t))

    if (hiddenTypes.length) {
      hiddenTypes.forEach(t => {
        const { [t]: type } = annotationTypeMap

        if (type && type.queryParam) {
          query[type.queryParam] = null
        }
      })
    }

    return query
  },
  defaultMemoizeOneShallowEquals
)

const hydrateBoolean = rawVal => {
  if (rawVal === 'true') return true
  if (rawVal === 'false') return false
  return !!Number(rawVal)
}

const hydrateDateTime = rawVal => {
  if (rawVal) {
    const parsed = getDateTime(rawVal)
    if (parsed.isValid) {
      return parsed
    }
  }
  return undefined
}

const hydrateList = list => {
  if (list === EMPTY_ARRAY) return list
  if (Array.isArray(list)) {
    return list.map(Number)
  }
  if (typeof list === 'number') {
    return [list]
  }
  return (list.includes(':') ? list.split(':') : Array.of(list)).map(Number)
}

/**
 * Hydrators to convert query params to expected room dashboard state values
 * @type {Object.<string, function>}
 * @property {function(any): string} $default - Default hydrator that is used if key not in hydrators
 */
const queryHydrators = {
  $default: String,
  annotationUser: Number,
  dataTypes: list => {
    if (Array.isArray(list)) {
      return list
    }
    return list.includes(':') ? list.split(':') : Array.of(list)
  },
  displayedZone: Number,
  harvest: Number,
  individualGraphs: hydrateBoolean,
  individualSensors: hydrateBoolean,
  journalOpen: hydrateBoolean,
  showRoomAvg: hydrateBoolean,
  showYAxis: hydrateBoolean,
  zones: hydrateList,
  depths: hydrateList,
  from: hydrateDateTime,
  to: hydrateDateTime,
}

export const rehydrateQuery = memoizeOne((rawQuery, withDefaults = false, includeNonTransferable = false) => {
  let query = pickStateFromQuery(rawQuery)
  if (withDefaults) {
    query = Object.entries(urlToStateMap).reduce((acc, [settingsKey, stateKey]) => {
      if (!includeNonTransferable && nonTransferableKeys.has(settingsKey)) return acc
      if (settingsKey in query) {
        acc[settingsKey] = query[settingsKey]
      } else {
        if (defaultState[stateKey] == null || defaultState[stateKey] === EMPTY_ARRAY) {
          return acc
        }
        acc[settingsKey] = defaultState[stateKey]
      }
      return acc
    }, {})
  }

  return Object.entries(query).reduce((acc, [key, rawVal]) => {
    const { [key]: hydrator = queryHydrators.$default } = queryHydrators

    return hydrator
      ? {
        ...acc,
        [key]: rawVal == null ? rawVal : hydrator(rawVal),
      }
      : acc
  }, EMPTY_OBJECT)
}, defaultMemoizeOneShallowEquals)

export const queryToPatch = (rawQuery, withDefaults = false, includeNonTransferable = false) => {
  const rehydrated = rehydrateQuery(rawQuery, withDefaults, includeNonTransferable)
  return Object.entries(rehydrated).reduce((patch, [key, val]) => {
    patch[urlToStateMap[key]] = val
    return patch
  }, {})
}

const equals = memoizeOne(
  (previous, next, which = 'queries') => {
    if (which != 'queries') {
      logger.debug('comparing settings:', { previous, next })
    }
    let diffKey
    const isEqual = Object.entries(next).every(([key, value]) => {
      if (key in previous) {
        const areEqual = previous[key] == null
          ? value == previous[key]
          : shallowEquals(value, previous[key])
        if (!areEqual) diffKey = key
        return areEqual
      }
      if (value === undefined) {
        return true
      }
      diffKey = key
      return false
    })
    if (diffKey) logger.debug(which, 'are different because of', diffKey, { prev: previous[diffKey], next: next[diffKey] })
    return isEqual
  },
  (left, right) => shallowEquals(left, right, 3)
)

const getGrowlogPersisted = either(path(['harvest', 'growlog']), always(EMPTY_OBJECT))
const getRoomPersisted = either(path(['room', 'chart']), always(EMPTY_OBJECT))

export const useUrlState = state => {
  const { growlog, initialized: loaded, loading = false, selectedZones } = state

  const {
    mySettings,
    queryAdvanced,
    doUpdateQueryAdvanced,
    doUpdateMySettings,
  } = useConnectModern(
    'selectMySettings',
    'selectQueryAdvanced',
    'doUpdateQueryAdvanced',
    'doUpdateMySettings',
  )
  const prevQuery = rehydrateQuery(queryAdvanced)
  const newQuery = getQuery(state)

  if (Boolean(newQuery.from || newQuery.to) && newQuery.timeframe) {
    newQuery.timeframe = undefined
  }
  if (newQuery.harvest && newQuery.zones?.length) {
    newQuery.zones = undefined
  }
  Object.keys(newQuery).forEach(key => {
    if (Array.isArray(newQuery[key]) && !newQuery[key].length) {
      newQuery[key] = undefined
    }
  })

  const newPersist = persistPicker(getQuery(state, true))
  const prevPersist = (growlog ? getGrowlogPersisted : getRoomPersisted)(mySettings)
  const urlDebounce = useRef(0)

  useEffect(() => {
    if (!loaded || loading) {
      return
    }

    const now = Date.now()
    if (urlDebounce.current > now - 500) {
      // logger.debug('debouncing url update', now - urlDebounce.current, 'ms since last call')
      urlDebounce.current = Date.now()
      return
    }
    const queryChanged = !equals(prevQuery, newQuery)
    const persistChanged = !equals(prevPersist, newPersist, 'settings')
    if (queryChanged || persistChanged) {
      logger.debug({
        prevQuery,
        newQuery,
        prevPersist,
        newPersist,
        queryChanged,
        persistChanged,
      })
    }
    if (queryChanged) {
      doUpdateQueryAdvanced(newQuery, { merge: true, replace: true })
    }

    if (persistChanged) {
      doUpdateMySettings({
        path: growlog ? SETTINGS_PATHS.growlog : SETTINGS_PATHS.roomDashboard,
        value: newPersist
      })
    }
    urlDebounce.current = Date.now()
  }, [
    prevQuery,
    newQuery,
    prevPersist,
    newPersist,
    queryAdvanced,
    loading,
    loaded,
    growlog,
    doUpdateMySettings,
    doUpdateQueryAdvanced,
    selectedZones
  ])
}
