import memoizeOne from 'memoize-one'
import {
  filter,
  partition,
  pick,
  pipe,
  uniqBy,
} from 'ramda'

import { getUniqueRanges, haveAlerts, haveTargets } from '~/src/DataType/TargetRange/utils'
import createLogger from '~/src/Lib/Logging'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  getDateTime,
  memoize,
  shallowEqualsMemoizer,
} from '~/src/Lib/Utils'
import { path as lightsOnPath } from '~/src/UI/Icons/LightsOn'

import { DATA_GROUPING, ROOM_GRAPH_SINGLE } from './constants'

const logger = createLogger('Chart/Utils#Guides')

const DAY_RANGE_KEYS = [
  'alertMax',
  'alertMin',
  'targetMax',
  'targetMin',
]
const NIGHT_RANGE_KEYS = [
  'nightAlertMax',
  'nightAlertMin',
  'nightTargetMax',
  'nightTargetMin',
]

const getTopLevelGuides = memoizeOne(({ chartData, theme }) => {
  let guides = chartData.guides ?? EMPTY_ARRAY
  if (guides.some(guide => guide.type === 'lights_on')) {
    /* const lightsOn = createElement(LightsOn) */
    guides = guides.map(guide => (guide.type === 'lights_on'
      ? { ...guide, icon: lightsOnPath, iconFillColor: theme.palette.gray }
      : guide))
  }
  return guides
})

export const getActiveTargets = shallowEqualsMemoizer(({
  chartData,
  activeTargetType,
  growlog,
  phases,
  room: selectedRoom,
  selectedHarvest,
  selectedZones,
  targetRanges,
}) => {
  const { range } = chartData
  if (!activeTargetType || !range) {
    return null
  }
  const rangeInterval = getDateTime(range.start).until(getDateTime(range.end))
  const selectedZonesSet = new Set(selectedZones)
  const activeTargets = (activeTargetType && range) ? pipe(
    Object.values,
    filter(({ dataTypeKey, phaseId, room, startDate, endDate }) => {
      const notActiveType = dataTypeKey !== activeTargetType.key
      if (notActiveType) {
        return false
      }
      let inRange = false
      if (!startDate) return inRange
      if (endDate) {
        const targetInterval = getDateTime(startDate).until(getDateTime(endDate))
        inRange = rangeInterval.overlaps(targetInterval)
      } else {
        inRange = getDateTime(startDate) <= rangeInterval.end
      }

      if (!inRange) {
        return false
      }

      if (!growlog) {
        if (Array.isArray(selectedRoom?.currentHarvests) && phases?.[phaseId]) {
          const { currentHarvests: harvests } = selectedRoom
          const { [phaseId]: phase = EMPTY_OBJECT } = phases
          const { harvestId, phaseType } = phase
          const harvest = harvestId ? harvests.find(({ id }) => harvestId == id) : null
          if (!harvestId || !harvest) return false

          // This is a HG phase target range; filter by selected zones
          const zonesKey = `${phaseType.toLowerCase()}Zones`
          const cultivars = harvest?.cultivars ?? []

          return cultivars.some(c => c[zonesKey]?.some(zone => selectedZonesSet.has(zone)))
        }
        return room === selectedRoom?.id
      }
      if (selectedHarvest?.phases?.length) {
        return selectedHarvest.phases.some(({ id }) => phaseId === id)
      }
      return false
    }),
    getUniqueRanges
  )(targetRanges) : null

  return activeTargets
}, { depth: 2 })

const hasSameNightDay = targetRange => {
  const {
    alertMin,
    alertMax,
    nightAlertMin,
    nightAlertMax,
    targetMin,
    targetMax,
    nightTargetMin,
    nightTargetMax,
  } = targetRange

  return alertMin === nightAlertMin
    && alertMax === nightAlertMax
    && targetMin === nightTargetMin
    && targetMax === nightTargetMax
}

const getTargetGuides = ({
  chartData,
  theme,
  activeTargetType,
  growlog,
  room: selectedRoom,
  selectedHarvest,
  selectedZones,
  targetRanges,
  viewMode,
  dataTypes,
  graphDataTypes,
  groups,
  phases,
}) => {
  const {
    range = EMPTY_OBJECT,
    guides = EMPTY_ARRAY
  } = chartData
  const activeTargets = getActiveTargets({
    chartData,
    activeTargetType,
    growlog,
    phases,
    room: selectedRoom,
    selectedHarvest,
    selectedZones,
    targetRanges,
  })
  // If no targets or no chart data range, we can't plot guides
  if (!activeTargets?.length || !range || (!range.start && !range.end)) {
    return groups
  }
  const chartStart = getDateTime(range.start)
  const chartEnd = getDateTime(range.end)

  const [nightDayTargets, allTimeTargets] = partition(tr => tr.useNightDay && !hasSameNightDay(tr), activeTargets)
  const effectiveTarget = memoize(tr => {
    const { startDate, endDate: originalEndDate } = tr
    const startDT = getDateTime(startDate)
    let endDate = originalEndDate
    if (tr.phaseId) {
      endDate = getDateTime(endDate).minus({ milliseconds: 1 })
    }
    const endDT = endDate ? getDateTime(endDate) : chartEnd
    return pick([
      ...DAY_RANGE_KEYS,
      ...NIGHT_RANGE_KEYS,
      'startDate',
      'endDate',
      'dataTypeKey',
    ], {
      ...tr,
      startDate: chartStart > startDT ? range.start : startDT.toUTC().toISO(),
      endDate: chartEnd > endDT ? endDT.toUTC().toISO() : range.end
    })
  })

  const fillAlpha = theme.palette.states.active
  const targetColor = theme.palette.success.main
  const alertColor = theme.palette.error.main

  const targetGuides = []
  if (nightDayTargets.length) {
    targetGuides.push(...uniqBy(effectiveTarget, nightDayTargets).flatMap(targetRange => {
      const result = []
      const {
        alertMax,
        alertMin,
        nightAlertMax,
        nightAlertMin,
        dataTypeKey,
        startDate,
        endDate,
        targetMax,
        targetMin,
        nightTargetMax,
        nightTargetMin,
      } = effectiveTarget(targetRange)
      const { [dataTypeKey]: dataType } = dataTypes
      let targetStart = getDateTime(startDate)
      if (chartStart > targetStart) {
        targetStart = chartStart
      }
      let targetEnd = getDateTime(endDate).endOf('day')
      if (chartEnd < targetEnd || !endDate) {
        targetEnd = chartEnd
      }
      const targetInterval = targetStart.until(targetEnd)
      const lastGuideIndex = guides.length - 1

      guides.forEach((guide, index) => {
        const { date, toDate } = guide
        const guideStart = getDateTime(date)
        const guideEnd = getDateTime(toDate)
        const guideInterval = guideStart.until(guideEnd)

        if (!targetInterval.overlaps(guideInterval)) return
        const beforeLights = []
        const duringLights = []
        const afterLights = []
        // Add overlays that coincide with the current lights-on guide
        if (alertMax != null && alertMin != null) {
          // add lights-on alert overlay
          duringLights.push({
            get type() { return `DayAlertMax_${this.date}_${this.toDate}` },
            toY: alertMax,
            date,
            toDate,
            fillAlpha,
            fillColor: alertColor,
            dataType: dataTypeKey,
          }, {
            get type() { return `DayAlertMin_${this.date}_${this.toDate}` },
            y: alertMin,
            date,
            toDate,
            fillAlpha,
            fillColor: alertColor,
            dataType: dataTypeKey,
          })
        }
        if (targetMax != null && targetMin != null) {
          result.push({
            type: `TargetRange_${dataType.key}`,
            toY: targetMin,
            y: targetMax,
            date,
            toDate,
            fillAlpha,
            fillColor: targetColor,
            dataType: dataTypeKey,
          })
        }

        // Add overlays that fall
        // - before the first lights-on guide (if this is the first)
        // - between the current lights-on guide and the next (if there is a next)
        // - after the last lights-on guide (if this is the last)
        const { [index + 1]: nextGuide = EMPTY_OBJECT } = guides
        if (nightAlertMax != null && nightAlertMin != null) {
          // if this is the first lights-on guide and there's chart before it, add night overlay
          if (index == 0 && targetStart < guideStart) {
            beforeLights.push({
              get type() { return `NightAlertMax_${this.date}_${this.toDate}` },
              toY: nightAlertMax,
              date: targetStart.toISO(),
              toDate: guideStart.toISO(),
              fillAlpha,
              fillColor: alertColor,
              dataType: dataTypeKey,
            }, {
              get type() { return `NightAlertMin_${this.date}_${this.toDate}` },
              y: nightAlertMin,
              date: targetStart.toISO(),
              toDate: guideStart.toISO(),
              fillAlpha,
              fillColor: alertColor,
              dataType: dataTypeKey,
            })
          }
          // if there's no next lights-on guide
          if (index == lastGuideIndex) {
            if (targetEnd > guideEnd) {
              // if there is chart left after the current lights-on guide, add night overlay
              afterLights.push({
                get type() { return `NightAlertMax_${this.date}_${this.toDate}` },
                toY: nightAlertMax,
                date: guideEnd.toISO(),
                toDate: targetEnd.toISO(),
                fillAlpha,
                fillColor: alertColor,
                dataType: dataTypeKey,
              }, {
                get type() { return `NightAlertMin_${this.date}_${this.toDate}` },
                y: nightAlertMin,
                date: guideEnd.toISO(),
                toDate: targetEnd.toISO(),
                fillAlpha,
                fillColor: alertColor,
                dataType: dataTypeKey,
              })
            }
          } else {
            // add night target overlay between end of current light-on guide and start of next
            afterLights.push({
              get type() { return `NightAlertMax_${this.date}_${this.toDate}` },
              toY: nightAlertMax,
              date: toDate,
              toDate: nextGuide.date,
              fillAlpha,
              fillColor: alertColor,
              dataType: dataTypeKey,
            }, {
              get type() { return `NightAlertMin_${this.date}_${this.toDate}` },
              y: nightAlertMin,
              date: toDate,
              toDate: nextGuide.date,
              fillAlpha,
              fillColor: alertColor,
              dataType: dataTypeKey,
            })
          }
        }
        if (nightTargetMax != null && nightTargetMin != null) {
          // if this is the first lights-on guide and there's chart before it, add night overlay
          if (index == 0 && targetStart < guideStart) {
            beforeLights.push({
              type: `NightTargetRange_${dataType.key}`,
              toY: nightTargetMin,
              y: nightTargetMax,
              date: targetStart.toISO(),
              toDate: guideStart.toISO(),
              fillAlpha,
              fillColor: targetColor,
              dataType: dataTypeKey,
            })
          }
          // if there's no next lights-on guide
          if (index == lastGuideIndex) {
            // if there is chart left after the current lights-on guide, add night overlay
            if (targetEnd > guideEnd) {
              afterLights.push({
                type: `NightTargetRange_${dataType.key}`,
                toY: nightTargetMin,
                y: nightTargetMax,
                date: guideEnd.toISO(),
                toDate: targetEnd.toISO(),
                fillAlpha,
                fillColor: targetColor,
                dataType: dataTypeKey,
              })
            }
          } else {
            // add night target overlay between end of current light-on guide and start of next
            afterLights.push({
              type: `NightTargetRange_${dataType.key}`,
              toY: nightTargetMin,
              y: nightTargetMax,
              date: toDate,
              toDate: nextGuide.date,
              fillAlpha,
              fillColor: targetColor,
              dataType: dataTypeKey,
            })
          }
        }
        if (beforeLights.length) result.push(...beforeLights)
        if (duringLights.length) result.push(...duringLights)
        if (afterLights.length) result.push(...afterLights)
      })
      return result
    }))
  }
  if (allTimeTargets.length) {
    targetGuides.push(...uniqBy(effectiveTarget, allTimeTargets).flatMap(targetRange => {
      const result = []
      const { dataTypeKey, startDate, endDate } = targetRange
      const date = startDate > range.start ? startDate : range.start
      const toDate = endDate < range.end ? endDate : range.end

      if (haveAlerts(targetRange)) {
        const { alertMax, alertMin } = targetRange
        if (alertMax && alertMin) {
          result.push({
            name: 'alertMax',
            toY: alertMax,
            date,
            toDate,
            fillAlpha,
            fillColor: alertColor,
            dataType: dataTypeKey,
          }, {
            name: 'alertMin',
            y: alertMin,
            date,
            toDate,
            fillAlpha,
            fillColor: alertColor,
            dataType: dataTypeKey,
          })
        }
      }

      if (haveTargets(targetRange)) {
        const { targetMax, targetMin } = targetRange
        result.push({
          name: 'TargetRange',
          toY: targetMin,
          y: targetMax,
          date,
          toDate,
          fillAlpha,
          fillColor: targetColor,
          dataType: dataTypeKey,
        })
      }
      return result
    }))
  }

  const {
    [viewMode]: groupingHandler = DATA_GROUPING[ROOM_GRAPH_SINGLE],
  } = DATA_GROUPING

  const newGroups = groupingHandler({
    data: targetGuides,
    dataTypes,
    graphDataTypes,
    theme,
    type: 'guides',
    reducerInitial: groups,
  })

  const { 0: firstGuide } = targetGuides

  const [targetKey, targetGroup] = Object.entries(newGroups).find(([, group]) => (
    group.guides && group.guides.includes(firstGuide)
  )) ?? EMPTY_ARRAY

  if (targetGroup) {
    const graphs = targetGroup.graphs.map(graph => {
      const { dataTypeKey } = activeTargets[0]
      const { dataType } = graph
      if (dataType !== dataTypeKey) {
        return { ...graph, strokOpacity: 0.15 }
      }
      return graph
    })
    return {
      ...newGroups,
      [targetKey]: { ...targetGroup, graphs }
    }
  }

  return newGroups
}

export const getGuides = props => ({ guides: getTopLevelGuides(props), groups: getTargetGuides(props) })
