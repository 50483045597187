import {
  activeUserAllowedTypes,
  annotationTypeMap,
  defaultAllowedTypes,
} from '~/src/Annotations/constants'
import { IRRIGATION_VIEW_SPLIT } from '~/src/Chart'
import { selectUniqueCultivarZones, selectZonesForHarvest } from '~/src/Harvest/bundle'
import {
  EMPTY_ARRAY as $A,
  EMPTY_OBJECT as $O,
  getDateTime,
  getId,
  shallowEquals,
} from '~/src/Lib/Utils'
import { selectRoomDashboardGraphs, selectTimeframeFrom } from '~/src/Room/bundle'
import { TIMEFRAME_PRESETS, ZOOM_MODE } from '~/src/Room/constants'

import { defaultState } from './defaults'
import { createNamespacedLogger } from './logger'
import { getQueryParam, rehydrateQuery } from './query'

const logger = createNamespacedLogger('utils#reducer')

export const shortCircuitWhenSame = (reducer, key) => (
  state = $O,
  action = $O,
  isSame = (a, b) => Object.is(a, b)
) => {
  if (isSame(state[key], action.payload)) {
    return state
  }
  return reducer(state, action)
}

export const shortCircuitWhenShallowEqual = (reducer, key) => (state = $O, action = $O) => {
  let { payload } = action
  if (typeof payload === 'function') {
    payload = payload(state[key])
  }
  if (shallowEquals(state[key], payload)) {
    return state
  }
  return reducer(state, { ...action, payload })
}

export const getInitialState = props => {
  const {
    growlog,
    isMobile,
    isLandscape,
    chart = $O,
    defaultDataTypes,
    harvest,
    harvests,
    query: rawQuery,
    room,
    settings,
    users,
    allDataTypes,
  } = props
  const query = rehydrateQuery({ ...settings, ...rawQuery })
  const { zones } = room
  const allZoneIds = zones?.map(getId) ?? $A
  const {
    data: chartData,
  } = chart
  const now = getDateTime('now')
  const viewMode = getQueryParam(query, 'viewMode', defaultState.viewMode)

  const queryHarvest = getQueryParam(query, 'harvest', null)
  let selectedHarvest = harvest
    ?? room.currentHarvests?.find(h => h.id === queryHarvest)
    ?? defaultState.selectedHarvest
  const { zones: queryZones = $A } = query

  let selectedZones = growlog
    ? selectUniqueCultivarZones(harvest?.cultivars ?? $A)
    : $A
  if (!growlog) {
    if (!selectedZones?.length && queryZones?.length) {
      selectedZones = queryZones
    }

    if (
      !selectedZones?.length
      && !selectedHarvest
      && room.currentHarvests?.length === 1
    ) {
      const [onlyHarvest] = room.currentHarvests
      const harvestZones = selectZonesForHarvest(onlyHarvest)
      const onlyHarvestInAllZones = allZoneIds.every(zoneId => harvestZones.includes(zoneId))

      if (onlyHarvestInAllZones) {
        selectedHarvest = typeof onlyHarvest === 'number' ? harvests[onlyHarvest] : onlyHarvest
        selectedZones = harvestZones
      }
    } else if (
      selectedHarvest
      && !room.currentHarvests?.find(({ id }) => id === selectedHarvest.id)
    ) {
      selectedHarvest = defaultState.selectedHarvest
    }

    if (selectedHarvest && !selectedZones?.length) {
      selectedZones = selectZonesForHarvest(selectedHarvest)
    }

    selectedZones = selectedZones?.filter(zone => allZoneIds.includes(zone))

    if (!selectedZones?.length) {
      selectedZones = allZoneIds ?? $A
    }

    if (viewMode === IRRIGATION_VIEW_SPLIT) {
      selectedZones = selectedZones.slice(0, 1)
    }
  }
  let manualTo = query.to ? getDateTime(query.to) : null
  let manualFrom = query.from && +(query.from || 0) !== +selectTimeframeFrom()
    ? getDateTime(query.from)
    : null
  if (manualFrom && !manualTo) {
    manualFrom = now > manualFrom ? manualFrom : null
  }

  if (harvest && harvest.startDate) {
    const harvestStart = getDateTime(harvest.startDate)
    const harvestEnd = getDateTime(harvest.harvestDate ?? harvest.endDate ?? 'now')

    if (manualFrom) {
      manualFrom = (manualFrom >= harvestStart) && (manualFrom <= harvestEnd) ? manualFrom : null
    }
    if (manualTo) {
      manualTo = (manualTo >= harvestStart) && (manualTo <= harvestEnd) ? manualTo : null
    }
  }

  let chartTimeframe = getQueryParam(query, 'timeframe', null)
  logger.debug('initial chartTimeframe:', chartTimeframe)
  if (chartTimeframe === 'selectedHarvest' && !selectedHarvest) {
    logger.debug('timeframe was "selectedHarvest" but no harvest present, resetting to default')
    chartTimeframe = defaultState.chartTimeframe
  } else if (growlog && !manualFrom && !manualTo) {
    const { endDate } = selectedHarvest ?? $O
    let harvestIsPast = false
    if (endDate) {
      logger.debug('harvest has endDate, checking if harvest is past')
      harvestIsPast = getDateTime(endDate).endOf('day') < now
    }
    chartTimeframe = harvestIsPast ? 'selectedHarvest' : (chartTimeframe || '1W')
    logger.debug('growlog timeframe:', chartTimeframe, { harvestIsPast })
  } else if (!TIMEFRAME_PRESETS[chartTimeframe] && chartTimeframe !== 'selectedHarvest') {
    chartTimeframe = (manualFrom || manualTo) ? null : defaultState.chartTimeframe
  }

  const activeAnnotationUser = query.annotationUser
    ? users[query.annotationUser] ?? null
    : null
  const activeAnnotationTypes = (activeAnnotationUser
    ? activeUserAllowedTypes
    : defaultAllowedTypes
  ).filter(t => {
    const { [t]: type } = annotationTypeMap
    return !(type.queryParam in query)
  })

  if (manualTo && manualTo <= manualFrom) {
    if (manualTo < now) {
      manualFrom = getDateTime(manualTo).minus({ days: 1 }).startOf('day')
    } else {
      manualTo = null
      manualFrom = null
      chartTimeframe = defaultState.chartTimeframe
    }
  }
  logger.debug('final chartTimeframe:', chartTimeframe)
  const selectedDataTypes = (getQueryParam(query, 'dataTypes', defaultDataTypes) ?? $A)
    .filter(dataType => dataType in allDataTypes)
  logger.debug(`[getInitialState] selectedDataTypes ${room?.name} (${room?.id})`, { selectedDataTypes, query, defaultDataTypes, allDataTypes })
  logger.debug(`[getInitialState] selectedZones ${room?.name} (${room?.id})`, { selectedZones, query, allZoneIds })
  const state = {
    ...defaultState,
    // TODO: when split graphs is released for indoor, this ternary needs to be removed
    activeAnnotationTypes,
    activeAnnotationUser,
    chartRange: {},
    chartTimeframe,
    cursorMode: ZOOM_MODE,
    individualGraphs: growlog ? false : getQueryParam(query, 'individualGraphs', defaultState.individualGraphs),
    individualSensors: getQueryParam(query, 'individualSensors', defaultState.individualSensors),
    journalOpen: getQueryParam(query, 'journalOpen', defaultState.journalOpen),
    mainGraphZone: getQueryParam(query, 'displayedZone', defaultState.mainGraphZone),
    manualFrom,
    manualTo,
    openAnnotation: getQueryParam(query, 'annotation', defaultState.openAnnotation),
    selectedDataTypes,
    selectedHarvest,
    selectedZones,
    showRoomAvg: getQueryParam(query, 'showRoomAvg', defaultState.showRoomAvg),
    showYAxis: getQueryParam(query, 'showYAxis', !isMobile || isLandscape),
    viewMode,
  }

  const chartGraphs = selectRoomDashboardGraphs({ ...state, chartData })
  return Object.assign(state, chartGraphs)
}
